import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Roadmap = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );
  
  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Roadmap - Coming soon',
    paragraph: 'Roadmap is on going.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            
          {/* <VerticalTimeline>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
              date="10%"
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
              // icon={<WorkIcon />}
            >
              <h3 className="vertical-timeline-element-title">Openning sales on Jomo APE</h3>
              <h4 className="vertical-timeline-element-subtitle"></h4>
              <p>
              First release of 1000 Jomo NFT to whitelist.<br/>
              Lauching the JOMO 3D Metaverse Forum
              </p>
              <br/>
                <ButtonGroup>
                  <Button tag="a" color="dark" wideMobile href="https://cruip.com/">
                    Buy On OpenSea
                    </Button>
                    <Button tag="b" color="dark" wideMobile href="https://cruip.com/">
                    FOMO Metaverse Forum
                    </Button>
                </ButtonGroup>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}

              date="20%"
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
              // icon={<WorkIcon />}
            >
              <h3 className="vertical-timeline-element-title">Developping JOMO Ecosystem</h3>
              <h4 className="vertical-timeline-element-subtitle"></h4>
              <p>
              Lauching JOMO Platform and JOMO Coin <br/>
              Jomo shop opening
              <br/>
              <br/>

              <ButtonGroup>
                  <Button tag="a" color="dark" wideMobile href="https://cruip.com/">
                    JOMO Platform
                    </Button>
                    <Button tag="b" color="dark " wideMobile href="https://cruip.com/">
                    Shop
                    </Button>
                </ButtonGroup>
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="30%"
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
              // icon={<WorkIcon />}
            >
              <h3 className="vertical-timeline-element-title">Networking on Meta Forum</h3>
              <h4 className="vertical-timeline-element-subtitle"></h4>
              <p>
                Conference <br/>
                Jomo Assembly <br/>
                
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="40%"
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
              // icon={<WorkIcon />}
            >
              <h3 className="vertical-timeline-element-title"></h3>
              <h4 className="vertical-timeline-element-subtitle"></h4>
              <p>
                
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--education"
              date="50%"
              iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
              // icon={<SchoolIcon />}
            >
              <h3 className="vertical-timeline-element-title"></h3>
              <h4 className="vertical-timeline-element-subtitle"></h4>
              <p>
                
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--education"
              date="80%"
              iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
              // icon={<SchoolIcon />}
            >
              <h3 className="vertical-timeline-element-title"></h3>
              <h4 className="vertical-timeline-element-subtitle"></h4>
              <p>
                
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--education"
              date="100%"
              iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
              // icon={<SchoolIcon />}
            >
              <h3 className="vertical-timeline-element-title"></h3>
              <h4 className="vertical-timeline-element-subtitle"></h4>
              <p>
                
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
              // icon={<StarIcon />}
            />
          </VerticalTimeline> */}
         

          </div>
        </div>
      </div>
    </section>
  );
}

Roadmap.propTypes = propTypes;
Roadmap.defaultProps = defaultProps;

export default Roadmap;