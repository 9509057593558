import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import Button from '../elements/Button';
const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Benefit from the Jomo membership',
    paragraph: 'When you buy a Jomo Ape, you’re not simply buying an avatar or a provably-rare piece of art. You are gaining membership access to a club whose benefits and offerings will increase over time. Your Jomo Ape can serve as your digital identity, and open digital doors for you.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-01.svg')}
                      alt="Features tile icon 01"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                  Fair Launch
                    </h4>
                  <p className="m-0 text-sm">
                  Fair Launch, fair distribution: All apes cost 0.08 ETH. Each drop will be notified to Jomo Community 
                    </p><br/><br/>
                    <Button tag="a" color="primary" wideMobile href="https://opensea.io/collection/jomo-ape-yacht-club">
                    Buy NFT
                    </Button>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-02.svg')}
                      alt="Features tile icon 02"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Networking
                    </h4>
                  <p className="m-0 text-sm">
                  Once you’re in, you can connect with everyone already in the club. Networking events will be organised in every major city!
                    </p>                    <br/>
                    <Button tag="a" color="dark" wideMobile disabled>
                    Coming soon
                    </Button>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-03.svg')}
                      alt="Features tile icon 03"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    JOMO Virtual Appartment
                    </h4>
                  <p className="m-0 text-sm">
                  Each Jomo NFT holder will have a virtual apartment, which they can use, customize and host friends for private conversations.
                    </p>
                    <br/>
                    <Button tag="a" color="primary" wideMobile href="/VirtualAppartment">
                    See Appartment
                    </Button>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-04.svg')}
                      alt="Features tile icon 04"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    JOMO DEFI Coin
                    </h4>
                  <p className="m-0 text-sm">
                  Our JOMO Coin will sustain the JOMO Defi Platform. Our members will have access to the best financial crypto services. We can help you buy, trade, loan and stake your coin at the best rate by comparing all existing platforms in real time.
                    </p><br/>
                    <Button tag="a" color="primary" wideMobile href="http://app.jomoape.com/">
                    See Defi platform
                    </Button>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-05.svg')}
                      alt="Features tile icon 05"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    JOMO Shop
                    </h4>
                  <p className="m-0 text-sm">
                    Premium access to our 3D store, Our unique and exclusive clothing collection reflect our values : Show to your friends the Joy Of Missing Out!
                    </p><br/>
                    <Button tag="a" color="primary" wideMobile href="https://app.spatial.io/rooms/61d9c8a3c963790001af9ac6?share=3149788666458854791">
                    Go to Virtual Shop
                    </Button>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-06.svg')}
                      alt="Features tile icon 06"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    JOMO 3D Gallery
                    </h4>
                  <p className="m-0 text-sm">
                  Showcase the JOMO collection and host virtual events and conferences with special guests on fascinating topics.                    </p><br/>
                    <Button tag="a" color="primary" wideMobile href="https://app.spatial.io/rooms/61d9c8a3c963790001af9ac6">
                    Go to Virtual Gallery
                    </Button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;