import React from 'react';
// import sections

import VirtualAppartv2 from '../components/sections/VirtualAppartv2';

const VirtualAppartment = () => {

  return (
    <>
    <VirtualAppartv2/>  

    </>
  );
}

export default VirtualAppartment;