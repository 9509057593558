import React from 'react';
// import sections
import HeroDefi from '../components/sections/HeroDefi';
import Enter from '../components/sections/Enter';
import FeaturesTilesDefi from '../components/sections/FeaturesTilesDefi';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Roadmap from '../components/sections/Roadmap';

import Cta from '../components/sections/Cta';

const Home = () => {

  return (
    <>
      <HeroDefi className="illustration-section-01" />
      <FeaturesTilesDefi />
      <Roadmap topDivider/> 
      <Cta split />
    </>
  );
}

export default Home;