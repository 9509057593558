import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }   

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
          <br/>
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              Benefit from a <span className="text-color-primary">Private Appartment</span>
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
              As a Jomo NFT owner, you'll have access to premium virtual apartments. Invite friends, chat and work in privacy. 
                </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">

              </div>
            </div>
          </div>
          <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">
            <a
              data-video="https://player.vimeo.com/video/174002812"
              href="#0"
              aria-controls="video-modal"
              onClick={openModal}
            >
              <Image
                className="has-shadow"
                src={require('./../../assets/images/jomo-virtual-appartment-nft.png')}
                alt="Hero"
                width={896}
                height={504} />
            </a>
          </div>          <br/>

          <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
              For now we have deploy a first version in production!  
                </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">

              </div>
            </div>
          <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">
            <a
              data-video="https://player.vimeo.com/video/174002812"
              href="#0"
              aria-controls="video-modal"
              onClick={openModal}
            >
              <Image
                className="has-shadow"
                src={require('./../../assets/images/JOMO-Virtual-Appartmentv1.png')}
                alt="Hero"
                width={896}
                height={504} />
            </a>
            <br/>
            <br/>

            <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                </p>
                <p >
                Audio virtual space Chat,
                Multifunctional Rooms with whiteboard,
                Flexible & Immediate Screen Sharing,
                Camera,                  
                Chat.
                Much more coming..
                </p>
                <ButtonGroup>
                  <Button tag="a" color="primary" wideMobile href="https://sky-office.co/">
                    Discover v1 
                    </Button>
                    <Button tag="a" color="seconday" wideMobile href="https://app.spatial.io/rooms/61db6574b4a12e0001120aa4?share=1812539716712019541">
                    Discover v2
                    </Button>
                    <Button tag="a" color="seconday" wideMobile href="https://oncyber.io/exhibits/aXMOZNfbcqFqDHxylffp">
                    Discover v2.1
                    </Button>
                </ButtonGroup>
          </div>
          <Modal
            />
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;